<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <Table
          :loading="$store.getters.customerTaskLoading"
          :headers="headers"
          :items="customerTasks"
          :total-records="customerTaskPagination.records_total || 0"
          :number-of-pages="customerTaskPagination.total_pages || 1"
          :footer="false"
          class="font-weight-medium mb-4"
          @pagination:update="updateCustomerTasks"
        >
          <template v-slot:item.srno="{ index }">
            {{ indexStart + index + 1 }}
          </template>
          <template v-slot:item.date="{ item }">
            <template v-if="item.future">
              <v-icon size="16" class="pb-1" color="#75FEF6">$dot</v-icon>
            </template>
            {{ getDateFormat(item.date) }}
          </template>
          <template v-slot:item.start_time="{ item }">
            {{ item.task_start_time }}
          </template>

          <template v-slot:item.end_time="{ item }">
            {{ item.task_end_time }}
          </template>

          <template v-slot:item.id="{ item }">
            <router-link
              :to="{ name: 'TaskView', params: { id: item.id } }"
              target="_blank"
            >
              {{ item.id }}
            </router-link>
          </template>

          <template v-slot:item.service="{ item }">
            <span v-if="item.service">{{ item.service.name }}</span>
          </template>

          <template v-slot:item.number_of_people="{ item }">
            <span v-if="item.task_member">{{ getSumMember(item) }}人</span>
          </template>

          <template v-slot:item.member="{ item }">
            <span v-if="item.task_member">
              <span
                v-for="(member, index) in item.task_member"
                :key="`member-${index}`"
              >
                <template v-if="index == 0">{{
                  member.last_name + ' ' + member.first_name
                }}</template>
                <template v-else>{{
                  ', ' + member.last_name + ' ' + member.first_name
                }}</template>
              </span>
            </span>
          </template>

          <template v-slot:top>
            <div class="mb-6">
              <v-row align="end">
                <v-col cols="auto" class="text-heading-1">
                  物件に関するタスク一覧
                </v-col>
                <v-col cols="auto" class="ml-4 text-heading-3 ">
                  全 {{ customerTaskPagination.records_total }} 件 あります。
                </v-col>
              </v-row>
            </div>
          </template>
        </Table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import Table from '@/components/admin/partials/Table/Table'

export default {
  name: 'Task',
  components: { Table },
  computed: {
    customerTasks() {
      return this.$store.getters.customerTasksById
    },
    customerTaskPagination() {
      return this.$store.getters.customerTaskPagination
    },
    indexStart() {
      return this.$store.getters.customerTaskPagination.records_from - 1
    }
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    projectId: {
      type: [Number, String]
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      srnocount: 1,
      page: 1,
      pageCount: 5,
      headers: [
        { text: '#', align: 'center', value: 'srno' },
        { text: '日付', align: 'center', value: 'date', sortable: false },
        {
          text: '開始時刻',
          align: 'center',
          value: 'start_time',
          sortable: false
        },
        {
          text: '終了時刻',
          align: 'center',
          value: 'end_time',
          sortable: false
        },
        {
          text: 'タスクID',
          align: 'center',
          value: 'id',
          sortable: false
        },
        {
          text: 'サービス',
          align: 'center',
          value: 'service',
          sortable: false
        },
        {
          text: '人数',
          align: 'center',
          value: 'number_of_people',
          sortable: false
        },
        {
          text: 'メンバー',
          align: 'center',
          value: 'member',
          sortable: false,
          width: '400'
        }
      ]
    }
  },
  mounted() {
    this.updateCustomerTasks(1)
  },
  methods: {
    getDateFormat(date) {
      return dayjs(date).format(`YYYY年MM月DD日`)
    },
    getSumMember(task) {
      let member = task.task_member.length
      let freelance = task.task_freelancer_users.reduce((a, b) => {
        return a + b.pivot.freelancers_count
      }, 0)
      return member + freelance
    },
    updateCustomerTasks(page) {
      this.page = page
      let param = {
        customer_id: this.$route.params?.customer_id,
        site_id: this.$route.params?.site_id,
        project_id: this.projectId,
        paginate: 10,
        page: page
      }
      this.$store.dispatch('CUSTOMER_TASK_GET', param)
    }
  }
}
</script>

<style lang="scss" src="./Task.scss" scoped></style>
