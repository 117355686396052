var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('Table', {
    staticClass: "font-weight-medium mb-4",
    attrs: {
      "loading": _vm.$store.getters.customerTaskLoading,
      "headers": _vm.headers,
      "items": _vm.customerTasks,
      "total-records": _vm.customerTaskPagination.records_total || 0,
      "number-of-pages": _vm.customerTaskPagination.total_pages || 1,
      "footer": false
    },
    on: {
      "pagination:update": _vm.updateCustomerTasks
    },
    scopedSlots: _vm._u([{
      key: "item.srno",
      fn: function fn(_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(_vm.indexStart + index + 1) + " ")];
      }
    }, {
      key: "item.date",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.future ? [_c('v-icon', {
          staticClass: "pb-1",
          attrs: {
            "size": "16",
            "color": "#75FEF6"
          }
        }, [_vm._v("$dot")])] : _vm._e(), _vm._v(" " + _vm._s(_vm.getDateFormat(item.date)) + " ")];
      }
    }, {
      key: "item.start_time",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.task_start_time) + " ")];
      }
    }, {
      key: "item.end_time",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.task_end_time) + " ")];
      }
    }, {
      key: "item.id",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'TaskView',
              params: {
                id: item.id
              }
            },
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(item.id) + " ")])];
      }
    }, {
      key: "item.service",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.service ? _c('span', [_vm._v(_vm._s(item.service.name))]) : _vm._e()];
      }
    }, {
      key: "item.number_of_people",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.task_member ? _c('span', [_vm._v(_vm._s(_vm.getSumMember(item)) + "人")]) : _vm._e()];
      }
    }, {
      key: "item.member",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [item.task_member ? _c('span', _vm._l(item.task_member, function (member, index) {
          return _c('span', {
            key: "member-".concat(index)
          }, [index == 0 ? [_vm._v(_vm._s(member.last_name + ' ' + member.first_name))] : [_vm._v(_vm._s(', ' + member.last_name + ' ' + member.first_name))]], 2);
        }), 0) : _vm._e()];
      }
    }, {
      key: "top",
      fn: function fn() {
        return [_c('div', {
          staticClass: "mb-6"
        }, [_c('v-row', {
          attrs: {
            "align": "end"
          }
        }, [_c('v-col', {
          staticClass: "text-heading-1",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" 物件に関するタスク一覧 ")]), _c('v-col', {
          staticClass: "ml-4 text-heading-3 ",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" 全 " + _vm._s(_vm.customerTaskPagination.records_total) + " 件 あります。 ")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }